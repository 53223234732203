export default {
  siteTitle: 'Get bonus',
  blogerName: 'VANN',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://youtube.com/@VanNGosu'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/c/vanNDota2'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/c/RATAMANCHELive'
    },
    {
      name: 'discord',
      url: 'https://discord.com/invite/HbqyUUt'
    },
    {
      name: 'telegram',
      url: 'https://t.me/+FUadHDMGQ_8zNjZh'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/vann.gosu'
    },
    {
      name: 'twitter',
      url: 'https://twitter.com/lolwingz'
    }
  ],
  projects: [
    {
      name: 'izzi',
      url: 'https://izzicasino.life/cb02ffbc7',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c14da802f',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/cfb3b456c',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c0979dcef',
      gameTitle: 'Candy Monsta (BGaming)',
    }
  ],
  headerText: 'Regístrate con mi código promocional <strong>VANN</strong> y obtén 50 giros gratis en los siguientes juegos',
  yourPromocode: 'Tu código promocional',
  gameBonus: 'Bono en el juego',
  promocode: 'VANN',
  casinoBonus1: '<strong>100% + 500FS</strong><div>en el primer depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado en el portapapeles',
  theme: 'dark',
};
